
const USER_INFO_URI = "https://retool-api-prod-mmlpw6jpvq-uc.a.run.app/users/me";
export const parseCredential = (credential) => {
	return {
		id_token: credential
		// expires_at: decoded.exp,
		// expires_in: tokenObj.expires_in,
		// first_issued_at: decoded.iat
	};
}

export const getSessionCredential = () => {
	const dataJson = sessionStorage.getItem('authToken');
	const credential = dataJson ? JSON.parse(dataJson) : undefined;
	return credential;
};

export const storeSessionCredential = (credential) => {
	sessionStorage.setItem('authToken', JSON.stringify(credential));
};


export const clearAuthetication = (reload) => {
	console.log("clearAuthetication")
	/* Clear session and reload window since authentication failed */
	sessionStorage.clear();
	if (reload) window.location.reload();
};


export const refreshCredential = async (credential) => {
	const currentUser = window.gapi.auth2.getAuthInstance().currentUser.get();
	const tokenObj = await currentUser.reloadAuthResponse().then(results => {
      })
	const newCredential = parseCredential(tokenObj);
	storeSessionCredential(newCredential);

	return newCredential;
};


export const fetchUserInfo = async (credentials) => {
	const userInfo = await fetch(USER_INFO_URI, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'api-auth-token': credentials.id_token
		},
	}).then(async data => {
		return await data.json();
	}).catch((error) => {
		console.error("Fail call api: ", error);
	});
	return userInfo.data;
};
